import createStore from 'unistore';
import devtools from 'unistore/devtools';

// initial state
export const ALERTS = 'alerts';
export const GAME_IDS = 'gameIds';
export const GAMES_BY_ID = 'gamesById';
export const BADGES_BY_ID = 'badgesById';
export const CATEGORIES_BY_ID = 'categoriesById';
export const CHALLENGES_BY_ID = 'challengesById';
export const FAVORITE_GAME_CODES = 'favoriteGameCodes';
export const SORTED_FAVORITE_GAME_CODES = 'sortedFavoriteGameCodes';
export const HEADER = 'header';
export const CATEGORY_IDS = 'categoryIds';
export const RECOMMENDED_GAME_CODES = 'recommendedGameCodes';
export const BODY = 'body';
export const AVATAR = 'avatar';
export const CHALLENGE_POINTS_TRACKER = 'challengePointsTracker';
export const SEO = 'seo';

// added state // TODO arrange below by features like challenges, avatar, etc
export const USER = 'user';
export const ERROR = 'error';
export const URL = 'url';
export const ROUTE = 'route';
export const FEATURED_CHALLENGES_STATUS = 'featuredChallengesStatus';
export const FEATURED_CHALLENGE_IDS = 'featuredChallengeIds';
export const ACTIVE_CHALLENGES_STATUS = 'activeChallengesStatus';
export const ACTIVE_CHALLENGES_IDS = 'activeChallengesIds';
export const SPOTLIGHT = 'spotlight';
export const SPOTLIGHT_STATUS = 'spotlightStatus';
export const EVENTS_STATUS = 'eventsStatus';
export const EVENT_IDS = 'eventIds';
export const EVENTS_BY_ID = 'eventsById';
export const GA4_CLIENT_ID = 'ga4ClientId';
export const GA4_SESSION_ID = 'ga4SessionId';
export const STATUS = 'status';
export const PROMO = 'promo';
export const ONLINE_STATUS = 'onlineStatus';

// favorite badge picker
export const UNSAVED_FAVORITE_BADGE_IDS_BY_SLOT = 'unsavedFavoriteBadgeIdsBySlot';
export const SELECTED_BADGE_SLOT = 'selectedBadgeSlot';
export const FAV_BADGE_API_STATUS = 'favoriteBadgeApiStatus';

// avatar state
export const AVATAR_ID = 'avatarId';
export const AVATAR_IMAGE_PATH = 'image';
export const AVATAR_SMALL_IMAGE_PATH = 'smallImage';
export const AVATAR_PRESTIGE = 'prestige';
export const AVATAR_TYPE = 'type';

// alert state
export const ALERT_TYPE = 'type';
export const ALERT_LOCATION = 'location';
export const ALERT_DATA = 'data';
export const COPPA_ALERT = 'coppaAlert';

// filter state
export const SORT_BY = 'sortBy';
export const GAME_CODES = 'gameCodes';
export const SORTED_GAME_CODES = 'sortedGameCodes';
export const GRID_STATUS = 'gridStatus';
export const ITEM_IDS = 'itemIds';
export const TOTAL_PAGES = 'totalPages';
export const COUNT = 'count';
export const PAGE_NUMBERS = 'pageNumbers';

// onboarding state
export const ONBOARDING = 'onboarding';
export const ONBOARDING_API_STATUS = 'onboardingApiStatus';
export const INTERSTITIALS = 'interstitials';
export const TOURS = 'tours';
export const TOOLTIPS = 'tooltips';
export const CLOSED = 'closed';
export const PRIZE = 'prize';
export const TOUR_ENABLED = 'tourEnabled';

// screen break points
export const SCREEN_BREAKPOINTS = 'screenBreakpoints';

// social
export const RTM_CONNECTION_STATE = 'rtmConnectionState'; // eadp social rtm connection state
export const USER_INFO_BY_NUCLEUS_IDS = 'userInfoByNucleusIds'; // A mapping of nucleus IDs to profile data for users
export const PLAYER_CARD_NUCLEUS_ID = 'chatPlayerCardNucleusId';
export const PLAYER_CARD_LOCATION = 'chatPlayerCardLocation'; // where to show the player card
export const PRESENCE_CONTAINER_STATUS = 'presenceContainerStatus';
export const SOCIAL_PLAYER_CARD_VISIBLE = 'socialPlayerCardVisible';

// friends state
export const PRESENCE_BY_NUCLEUS_IDS = 'presenceByNucleusIds';
export const FRIEND_NUCLEUS_IDS = 'friendNucleusIds';
export const BLOCKED_NUCLEUS_IDS = 'blockedNucleusIds';
export const INBOUND_INVITE_NUCLEUS_IDS = 'inboundInviteNucleusIds';
export const OUTBOUND_REQUEST_NUCLEUS_IDS = 'outboundRequestNucleusIds';
export const FRIENDS_CONTAINER_STATUS = 'friendsContainerStatus';
export const SEARCH_IDS = 'searchIds';

// chat state
export const CHAT_IS_FOCUSED = 'chatIsFocused'; // Indicates whether the chat frame currently has focus
export const CHAT_MEMBERS_LIST_VISIBLE = 'chatMembersListVisible';
export const CHAT_MEMBERS_LIST_CHANNEL_ID = 'chatMembersListChannelId';
export const CHAT_MEMBERS_FETCH_STATUS = 'chatMembersFetchStatus';
export const CHAT_ACTIVE_VIEW = 'chatActiveView';
export const CHAT_OPEN = 'chatOpen';

// game chat state
export const GAME_CHAT_CHANNEL_NAMES_BY_CHANNEL_ID = 'gameChatChannelNamesByChannelId'; // Map of channel Id to channel name
export const GAME_CHAT_CHANNELS_STATUS = 'gameChatChannelsStatus'; // Channels panel status (loading/loading/error)
export const GAME_CHAT_CHANNEL_IDS = 'gameChatChannelIds'; // A list of channel IDs for the current game
export const GAME_CHAT_CURRENT_CHANNEL_ID = 'gameChatCurrentChannelId'; // Id of channel that the user is currently in or intends to join
export const GAME_CHAT_CURRENT_JOIN_STATUS = 'gameChatJoinStatus'; // Tracks the status of the current join operation, if any
export const GAME_CHAT_DND = 'gameChatDND'; // Boolean value to determine do not disturb user setting (true means do NOT distrub, false means notifications will appear)
export const GAME_CHAT_UNREAD_MESSAGE_COUNT = 'gameChatUnreadMessageCount';
export const GAME_CHAT_PLAYER_COUNT_BY_CHANNEL_ID = 'gameChatPlayerCountByChannelId'; // A mapping of channel ID to number of players currently in that channel
export const GAME_CHAT_FRIEND_COUNT_BY_CHANNEL_ID = 'gameChatFriendCountByChannelId'; // The number of friends in each channel

// private chat state
export const PRIVATE_CHAT_CURRENT_CHANNEL_ID = 'privateChatCurrentChannelId'; // Id of private chat channel that the user is currently in
export const PRIVATE_CHAT_CURRENT_NUCLEUS_ID = 'privateChatCurrentNucleusId'; // nucleus id of the current chat user
export const PRIVATE_CHAT_CURRENT_SUB_STATUS = 'privateChatSubStatus'; // Tracks the status of the current subscribe operation, if any
export const PRIVATE_CHAT_UNREAD_MESSAGE_COUNT = 'privateChatUnreadMessageCount';
export const PRIVATE_CHANNELS_BY_NUCLEUS_ID = 'privateChatChannelsByNucleusId';

// table chat state
export const TABLE_CHAT_CURRENT_CHANNEL_ID = 'tableChatCurrentChannelId'; // Id of table chat group channel that the user is currently in
export const TABLE_CHAT_CURRENT_GROUP_NAME = 'tableChatCurrentGroupName'; // Name of table chat group that the user should subscribe to
export const TABLE_CHAT_CURRENT_SUB_STATUS = 'tableChatSubStatus'; // Tracks the status of the current subscribe operation, if any
export const TABLE_CHAT_UNREAD_MESSAGE_COUNT = 'tableChatUnreadMessageCount';

// event state - general
export const EVENT_CHALLENGE_IDS = 'eventChallengeIds';
export const EVENT_BADGE = 'eventBadge';
export const EVENT_ID = 'eventId';
export const EVENT_CMS_REST_STATUS = 'eventCmsRestStatus';
export const EVENT_REST_STATUS = 'eventRestStatus';
export const EVENT_SPOTLIGHT = 'eventSpotlight';
export const EVENT_STATE = 'eventState'; // TODO: rename to EVENT_STATUS
export const EVENT_TIME_LEFT = 'eventTimeLeft';
export const EVENT_SHOW_TUTORIAL_PREVIEW = 'eventShowTutorialPreview';
export const EVENT_GOALS = 'goals';
export const EVENT_BUTTON_STATE = 'eventButtonState';
export const EVENT_AUTH_LEVELS = 'eventAuthLevels';

// event state - list template
export const EVENT_LIST_TYPE = 'eventListType';

// event state - tier template
export const EVENT_BOSS_CHALLENGE_ID = 'eventBossChallengeId';
export const EVENT_PROGRESS_IDS = 'eventProgressIds';
export const EVENT_TIER_IDS = 'eventTierIds';
export const EVENT_TIERS_BY_ID = 'eventTiersById';

// event state - scavenger hunt
export const EVENT_HINTS_BY_POSITION = 'eventHintsByPosition';
export const EVENT_HINT_POSITIONS = 'eventHintIds';

// event state - bingo jam
export const EVENT_USER_PREFERENCES = 'eventUserPreferences';
export const EVENT_REVEALED_IDS = 'revealedChallengeIds';
export const EVENT_SEEN_POPUP_IDS = 'seenPopupIds';

// event state - spinner event
export const EVENT_SPINS = 'spins';
export const EVENT_SPINNER_AWARDS = 'spinnerAwards';

// bundles state
export const BUNDLE_PACKS_BY_ID = 'bundlePacksById';
export const BUNDLE_COLLECTION = 'bundleCollection';

// inbox state

// complete array of sorted ids
export const INBOX_MESSAGE_IDS = 'inboxMessageIds';
// object with message data for each messageId
export const INBOX_MESSAGES_BY_ID = 'inboxMessagesById';
// index (from INBOX_MESSAGE_IDS array) of selected message, null if not selected
export const INBOX_MESSAGE_INDEX = 'inboxMessageIndex';
// view inside inbox route: either INBOX_MESSAGE_LIST or INBOX_MESSAGE_DETAIL
export const INBOX_ACTIVE_VIEW = 'inboxActiveView';
// If true, a popup shows before user deletes messages. If false, directly deletes them.
export const INBOX_SHOW_CONFIRM_DELETE = 'inboxShowConfirmDelete';
// If true, user will only receive messages from friends
export const INBOX_ONLY_ACCEPT_FRIEND_MESSAGES = 'inboxOnlyAcceptFriendMessages';
// number of unread inbox messages/notifications. located inside HEADER
export const INBOX_COUNT = 'inboxCount'; // inbox messages unread count
export const NOTIFICATION_COUNT = 'notificationCount';
export const GIFT_NOTIFICATION_COUNT = 'giftNotificationCount';
export const HEADER_COPY = 'headerCopy';

// consent banner
export const SHOW_CONSENT_BANNER = 'showConsentBanner';
export const CONSENT_MANAGER_LOADED = 'consentManagerLoaded';

// theme
export const THEME = 'theme';

// mini
export const MINI = 'mini';

// gift
export const DOID_BEING_GIFTED = 'doidBeingGifted';
export const GIFT_STATUS = 'giftStatus';

// experiments
export const EXPERIMENTS_BY_ID = 'experimentsById';
export const CLUB_UPSELL_ALERT = 'club_upsell_alert';
export const BANNER_UPSELL = 'banner_upsell';
export const CLUB_CHECKOUT = 'club_checkout';

export const REPORT_ABUSE = 'reportAbuse'; // contains all the info needed for report abuse flow

export let initialState = {
	[ALERTS]: undefined,
	[GAMES_BY_ID]: {},
	[BADGES_BY_ID]: {},
	[CATEGORIES_BY_ID]: {},
	[CHALLENGES_BY_ID]: {},
	[EVENT_TIERS_BY_ID]: {},
	[FAVORITE_GAME_CODES]: [],
	[SORTED_FAVORITE_GAME_CODES]: [],
	[HEADER]: {
		[CATEGORY_IDS]: [],
		[RECOMMENDED_GAME_CODES]: []
	},
	[BODY]: {},
	[AVATAR]: {},
	[CHALLENGE_POINTS_TRACKER]: {}, // TODO only used in 1 place, not needed in store?
	[SEO]: {}
};

export let store = process.env.NODE_ENV === 'development' ? devtools(createStore(initialState)) : createStore(initialState);

export let createTestStore = () => store;
