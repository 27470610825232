/* globals POGO_ENVIRONMENT, EXPERIMENTATION_HOST */
import { advertisementCookiesEnabled } from 'services/privacy';
import { sleep, stringify, isDefined, getUpsellSeenInLocalStorage } from 'util/index';
import { logError } from 'services/logger';
import { store, EXPERIMENTS_BY_ID, CLUB_UPSELL_ALERT, BANNER_UPSELL, CLUB_CHECKOUT } from 'util/store';
import { addEventListener } from '@pogo-internal/events/eventManager';
import { REGISTRATION, SUBSCRIPTION } from '@pogo-internal/events/events';

const EXPERIMENT_KEYS = [CLUB_UPSELL_ALERT, BANNER_UPSELL, CLUB_CHECKOUT,
	'dummyKey1', 'dummyKey2', 'dummyKey3', 'dummyKey4', 'dummyKey5'];
const TITLE_ID = 'pogoweb';
const TITLE_TYPE = 'service';
const PLATFORM = 'web';
const PROD_DOMAIN = '830';
const PREPROD_DOMAIN = '820';
const MAX_RETRIES = 3; // max number of times api is auto retried

let host = EXPERIMENTATION_HOST, domain = POGO_ENVIRONMENT === 'prod' ? PROD_DOMAIN : PREPROD_DOMAIN;

/**
 * The Experimentation Runtime Grouping Service API provides a programmatic interface for you to assign players to a variant group.
 * @param {User} user Platform user (Logged or Guest).
 * @param {String} experimentKeys is a list of experiment keys.
 * @param {String} titleId Title ID of the experiment.
 * @param {String} titleIdType Title ID type of the experiment.
 * @returns Returns a list of experiment results.
 */
export async function fetchAndSetExperimentsById(experimentKeys = EXPERIMENT_KEYS, titleId = TITLE_ID, titleIdType = TITLE_TYPE) {
	try {
		let { user, [EXPERIMENTS_BY_ID]: experimentsById = {}  } = store.getState();
		let attributes = { authLevel: user.authLevel, segments: user.segments };
		let platform = PLATFORM;
		let playerId = {};
		let newExperimentsById = { ...experimentsById };

		if (experimentKeys?.every(key => experimentsById[key])) return;

		if (user.underAge || !advertisementCookiesEnabled(user)) {
			experimentKeys?.forEach(key => newExperimentsById[key] = {});
			store.setState({
				[EXPERIMENTS_BY_ID]: newExperimentsById
			});
			return;
		}

		if (user.authenticated) {
			playerId.type = 'nucleus';
			playerId.id = user.nucleusId;
		}
		else {
			playerId.type = 'anonymous';
			playerId.id = user.unid;
		}

		const requestBody = {
			domain,
			experimentKeys,
			platform,
			playerId,
			titleId,
			titleIdType,
			attributes,
			isDebug: true
		};

		let { experiments } = await _post(`${host}/experimentation/v1/fetch-experiment-grouping`, requestBody);
		experimentsById = store.getState()[EXPERIMENTS_BY_ID] || {};
		newExperimentsById = { ...experimentsById };
		experiments?.forEach(experiment => newExperimentsById[experiment.experimentKey] = experiment.testParameters);
		
		store.setState({
			[EXPERIMENTS_BY_ID]: newExperimentsById
		});
	}
	catch (error) {
		logError(error, 'ExperimentationService - fetchAndSetExperimentsById');
	}
}

/**
 * The Experimentation Tracking Service API allows you to track custom, non-PIN telemetry data on how players react to different experiment variations. You can use this data later to judge the experimentation results.
 * @param {User} user Platform user (Logged or Guest).
 * @param {String} eventKey identifies the event you want to track. Event key is the primary tag in the reporting to identify different event source of metrics. The length should be at least 4 characters.
 * @param {Object} attributes Map of key as string, and value as boolean or string. Is used to send additional contextual metadata about player or event, used for visual segmentation of metrics in reporting page.
 * @param {Object} metrics Map of key as string and value as number. Defines arbitrary numeric values to associated with the corresponding event.
 * @param {String} titleId Title ID of the experiment.
 * @param {String} titleIdType Title ID type of the experiment.
 * @returns Returns a success or error message on whether the tracking request was filed successfully.
 */
export async function trackExperiment(eventKey, attributes, metrics,
									  titleId = TITLE_ID, titleIdType = TITLE_TYPE) {
	try {
		let { user } = store.getState();
		let platform = PLATFORM;
		let playerId = {};

		if (user.underAge || !advertisementCookiesEnabled(user)) {
			return;
		}

		if (user.authenticated) {
			playerId.type = 'nucleus';
			playerId.id = user.nucleusId;
		}
		else {
			playerId.type = 'anonymous';
			playerId.id = user.unid;
		}

		metrics = { ...metrics, quantity: 1 };
		attributes = {
			...attributes, authLevel: user.authLevel, segments: user.segments,
			...(user.authenticated && { internal_pogo_id: user.id }),
			internal_unid: user.unid
		};

		const requestBody = {
			domain,
			playerId,
			eventKey,
			titleId,
			titleIdType,
			platform,
			attributes,
			metrics
		};

		let headers = {
			type: 'application/json'
		};

		let blob = new Blob([JSON.stringify(requestBody)], headers);
		navigator.sendBeacon(`${host}/experimentation/v1/track-experiment`, blob);
	}
	catch (error) {
		if (!error.message.includes('crbug.com/490015')) { // silence chrome issue that happens in version < 80
			logError(error, 'ExperimentationService - trackExperiment');
		}
	}
}

// ---------------------------------------- Private Functions ----------------------------------------

function _status(response) {
	if (response.status >= 200 && response.status < 300) {
		return Promise.resolve(response);
	}
	return Promise.reject(response);
}

function _json(response) {
	return response.json().catch(() => ({}));
}

function _post(url, body, defaultResponse, retry) {
	body = _clearBody(body);
	return _request('post', url, body, defaultResponse, retry);
}

async function _request(method, url, body, defaultResponse = {}, retries = MAX_RETRIES) {
	let opt = {
		method,
		headers: { 'Content-Type': 'application/json' }
	};
	if (body) {
		opt.body = JSON.stringify(body);
	}
	try {
		let response = await fetch(url, opt);
		response = await _status(response);
		response = await _json(response);
		return response;
	}
	catch (error) {
		if (!error.status) {
			if (retries) {
				await sleep(Math.pow(2, MAX_RETRIES - retries + 1) * 1000); // exponential backoff algorithm
				return await _request(method, url, body, defaultResponse, retries - 1);
			}
			let restError = new Error(`rest api ${url} failed without a status ${stringify(error)}`);
			restError.status = 500;
			restError.type = 'restApi';
			throw restError;
		}
		else {
			return _json(error).then((errorObject = {}) => {
				let restError = new Error(`rest api ${url} failed with status ${error.status} - ${error.message || errorObject.error}`);
				restError.status = error.status;
				restError.type = 'restApi';
				restError.error = errorObject.error;
				throw restError;
			});
		}
	}
}

function _clearBody(body) {
	for (let key in body) {
		if (isDefined(body[key])) {
			continue;
		}
		else {
			delete body[key];
		}
	}
	return body;
}


export function enableExperimentTracking() {
	// track subscription conversion in club upsell experiment(s)
	addEventListener(SUBSCRIPTION, () => {
		let location = getUpsellSeenInLocalStorage();
		trackExperiment('subscription_confirmation', { location });
	});

	// track registration conversion in register free experiment(s)
	addEventListener(REGISTRATION, () => {
		let location = getUpsellSeenInLocalStorage();
		trackExperiment('registration_confirmation', { location });
	});
}