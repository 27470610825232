import { ALERT_DATA, ALERT_LOCATION, ALERT_TYPE, ALERTS, ERROR, STATUS, store, USER } from 'util/store';
import { dispatchEvent } from '@pogo-internal/events/eventManager';
import { CLICK_LOCATION, DESTINATION_URL } from '@pogo-internal/events/attributes';
import { CLICK } from '@pogo-internal/events/events';
import { getQueryStringParams } from 'library/util';
import { generateQueryParams, getInternalUrlFromParam, loadUrl, sendScrollEvent } from 'services/urlService';
import { logError } from 'services/logger';


// onboarding FTUE
export const APP_ELEMENT_ID = 'app';
export const PAGE_CONTENT  = 'pageContent';
export const RTM_ERROR = 'rtmError';
export const PRESENCE_ONLINE_STATUS = 3;

export const PAID_UA = 'paid-ua';
export const SUBS_OFFER_COUPON = 'subs-offer-coupon-code';
export const GUEST_FIRST_VISIT_TIME = 'guestFirstVisitTime';
export const AD_QUERY_PARAMS = 'ad-query-params';
export const AD_TRACKING_PARAMS = ['fbclid', 'gclid'];
export const UPSELL_SEEN = 'upsell-seen';
export const ONE_MINUTE = 60000;
export const FIVE_MINUTES = ONE_MINUTE * 5;
export const TEN_MINUTES = ONE_MINUTE * 10;
export const ONE_HOUR = ONE_MINUTE * 60;

let IOS = false;
let SUBS_OFFER_LENGTH = 12 * 60 * 60 * 1000;

(function () {
	IOS = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0 ||
		// iPadOS Safari 13.2.3
		('MacIntel'.indexOf(navigator.platform) >= 0 && window.orientation !== undefined);
})();

export function isIOS() {
	return IOS;
}

export function scrollToTop(t = 0) {
	let rootsChild = document.querySelector('#app > div');
	if (rootsChild) {
		setTimeout(() => {
			rootsChild.scrollIntoView();
		}, t);
	}
}

export function isDefined(value) {
	return value !== undefined && value !== null;
}

export function showError(status) {
	if (!store.getState()[ERROR]) {
		store.setState({
			[ERROR]: {
				[STATUS]: status
			}
		});
	}
}

export function showAlert(type, location, data = {}) {
	let { [ALERTS]: alerts = [] } = store.getState();
	if (!alerts.length) {
		store.setState({
			[ALERTS]: [
				{
					[ALERT_TYPE]: type,
					[ALERT_LOCATION]: location,
					[ALERT_DATA]: data
				}
			]
		});
	}
}

export function closeAlert(type, location, overlayClicked, doNotTrackClick, ga4Attributes = {}) {
	if (!doNotTrackClick) {
	    dispatchEvent(CLICK, {
			[CLICK_LOCATION]: (location ? `${location}-` : '') + `${type}-alert-close` + (overlayClicked ? '-overlay' : '-button'),
			...ga4Attributes
		});
	}
	let { [ALERTS]: alerts = [] } = store.getState();
	store.setState({
		[ALERTS]: alerts.filter(alert => alert.type !== type)
	});
}

export function getPurchasablePropsFromBundlePack(pack) {
	return {
		purchasable: {
			doid: pack?.digitalObjectId,
			label: 'Collections',
			title: pack?.name,
			image: pack?.squareImage,
			price: pack?.price,
			copy: 'This Collection Pack includes',
			type: 'pba',
			badgeCount: pack?.badgeCount,
			pogis: pack?.pogis
		}
	};
}

export function checkAdEnabled(user) {
	return user && !user.club;
}

// used for ad path names
export const componentToPageMap = {
	home: 'Homepage',
	pregame: 'Gamepage',
	challengeCentral: 'Challengecentral',
	challengeFilter: 'ChallengecentralFilter',
	search: 'Search',
	category: 'Gamecategory',
	favorites: 'Favorites'
};

export function getCookie(cname) {
	let name = cname + '=';
	let ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

export function sort(items, key, asc, alphabetical) {
	let get = (object, key) => {
		const value = object[key];
		return value ? (alphabetical ? value.toLowerCase() : value) : 0;
	};
	return [...(items || [])].sort((a, b) => {
		const aValue = get(a, key);
		const bValue = get(b, key);
		if (aValue < bValue) { return asc ? -1 : 1; }
		if (aValue > bValue) { return asc ? 1 : -1; }
		return 0;
	});
}

export function onAppScroll(listener) {
	const app = document.getElementById('app');
	app && app.addEventListener('optimizedScroll', listener);
}

export function removeOnAppScroll(listener) {
	const app = document.getElementById('app');
	app && app.removeEventListener('optimizedScroll', listener);
}

export function insertBefore(el, name, props) {
	let node = document.createElement(name);
	for (let key in props) {
		node.setAttribute(key, props[key]);
	}
	el.parentNode.insertBefore(node, el);
}

export function removeElement(el) {
	el && el.remove();
}

export function once(fn) {
	let canRun = true;
	return function runOnce(...args) {
		if (canRun) {
			canRun = false;
			return fn.apply(this, args);
		}
	};
}

export function isLocalStorage() {
	try {
		localStorage.setItem('test', 'test');
		localStorage.removeItem('test');
		return true;
	}
	catch (e) {
		return false;
	}
}

/**
 * returns a promise that resolves when fn() returns true
 * @param fn a function
 * @param t how often to check for fn being truthy
 * @returns {Promise}
 */
export function promisify(fn, t = 1, maxTries) {
	return new Promise((resolve, reject) => {
		let tries = 0;
		let timer = setInterval(() => {
			tries++;
			if (maxTries && tries > maxTries) {
				clearInterval(timer);
				return reject(new Error(`${fn} was untrue after ${maxTries} tries`));
			}
			if (fn()) {
				clearInterval(timer);
				return resolve();
			}
		}, t);
	});
}

export function getHiddenIframe(id, src) {
	let iframe = document.getElementById(id);
	if (!iframe) {
		iframe = document.createElement('iframe');
		iframe.style.display = 'none';
		iframe.id = id;
		document.body.appendChild(iframe);
	}
	src && (iframe.src = src);
	return iframe;
}

export function stringify(obj) {
	if (obj === null) {
		return 'null';
	}
	if (typeof obj === 'function') {
		return stringifyFn(obj);
	}
	if (typeof obj !== 'object') {
		return String(obj);
	}
	try {
		const flattened = copyForStringify(obj, 5);
		if (flattened.toJSON) {
			delete flattened.toJSON;
		}
		if (flattened.toObject) {
			delete flattened.toObject;
		}
		return JSON.stringify(flattened);
	}
	catch (e) {
		// If we fail to flatten/stringify then just stringify the original.
		return JSON.stringify(obj);
	}
}

// Performs a deep copy of the object, including inherited and non-enumerable properties, up to the specified depth
function copyForStringify(obj, depth) {
	return copyForStringifyRecursively(obj, depth, [obj]);
}

function copyForStringifyRecursively(obj, depth, history) {
	const copy = copyStringifiableKeys(obj, history);
	for (let key in copy) {
		let value = obj[key];
		if (typeof value === 'object') {
			if (depth > 1) {
				value = copyForStringifyRecursively(value, depth - 1, history);
			}
			else {
				value = '[Object]'; // We have reached the maximum depth
			}
		}
		copy[key] = value;
	}
	return copy;
}

// Returns a key-only copy of obj with only properties that should be stringified (based on rules in getStringifiableProperty())
function copyStringifiableKeys(obj, history) {
	const stringifiableObj = {};
	for (let key in obj) {
		const value = getStringifiableProperty(obj, key, history);
		if (value !== null) {
			stringifiableObj[key] = value;
		}
	}
	// Doing a semi-redundant pass of own property names in order to catch non-enumerable properties
	for (let key of Object.getOwnPropertyNames(obj)) {
		if (!Object.prototype.hasOwnProperty.call(stringifiableObj, key)) {
			const value = getStringifiableProperty(obj, key, history);
			if (value !== null) {
				stringifiableObj[key] = value;
			}
		}
	}
	return stringifiableObj;
}

// Returns the value of the key if it should be stringified and is not part of the history. If the value is an object, it is added to the history.
function getStringifiableProperty(obj, key, history) {
	if (key === 'toJSON') {
		return null;
	}
	let value = obj[key];
	if (value === null) {
		return null;
	}
	if (value === window) {
		return '[Window]';
	}
	if (value === window.document) {
		return '[Document]';
	}
	switch (typeof value) {
		case 'function': return null; // We don't stringify functions within objects
		case 'object':
			if (history.includes(value)) {
				return null; // We don't stringify objects that are in the history
			}
			history.push(value);
			return value;
		default: return value;
	}
}

function stringifyFn(fn) {
	return String(`function ${fn.name}`);
}

export function sleep(time) {
	return new Promise(resolve => setTimeout(resolve, time));
}

export function removeQueryParam(key) {
	let queryParams = getQueryStringParams();
	if (queryParams[key]) {
		queryParams[key] = undefined;
		let newUrl = updateQueryParam(location.href, queryParams);
		history.replaceState(null, null, newUrl);
	}
}

export function addQueryParam(key, value) {
	if (key && value) {
		let queryParams = getQueryStringParams();
		queryParams[key] = value;
		let newUrl = updateQueryParam(location.href, queryParams);
		history.replaceState(null, null, newUrl);
	}
}

export function addQueryParams(queryParams) {
	queryParams = {
		...getQueryStringParams(),
		...queryParams
	};
	let newUrl = updateQueryParam(location.href, queryParams);
	history.replaceState(null, null, newUrl);
}

function updateQueryParam(url, queryParams) {
	let urlWithoutFragment = url.split('#')[0];
	let fragmentValue = url.split('#')[1];
	let fragment = fragmentValue ? `#${fragmentValue}` : '';
	let newUrl = urlWithoutFragment.split('?')[0] + generateQueryParams(queryParams) + fragment;
	return newUrl;
}

export function updateFragmentParam(key, value, addIfAbsent = false) {
	let url = location.href;
	if (location.hash) {
		let params = getQueryStringParams(location.hash.replace('#', '?'));
		if (!params[key] && !addIfAbsent) {
			return;
		}
		params[key] = value;
		let newUrl = url.split('#')[0] + generateQueryParams(params).replace('?', '#');
		history.replaceState(null, null, newUrl);
	}
	else if (addIfAbsent) {
		let params = { [key]: value };
		let newUrl = url + generateQueryParams(params).replace('?', '#');
		history.replaceState(null, null, newUrl);
	}

}

export function getFromFragment(key) {
	if (location.hash) {
		try {
			const value = getQueryStringParams(location.hash.replace('#', '?'))[key];
			return value;
		}
		catch (e) {
			logError(e, 'getFromFragment');
		}
	}
}

export function buildStringWithParam(template, ...vars) {
	if (!template) {
		return '';
	}
	let processedString = template;
	for (let index = 0; index < vars.length; index++) {
		processedString = processedString.replace(new RegExp(`\\{${index}\\}`, 'g'), vars[index]);
	}
	return processedString;
}

export async function onReady() {
	if (document.readyState !== 'complete') {
		return new Promise((res) => window.addEventListener('load', res));
	}
	return Promise.resolve();
}

export function getFullscreenState() {
	return !!(document.fullscreenElement ||
		document.webkitFullscreenElement ||
		document.mozFullScreenElement ||
		document.msFullscreenElement);
}

export function dedupe(array) {
	return Array.from(new Set(array));
}

const PREFIX = 'com.pogo.';

export function getLocalStorage(key) {
	let value;
	try {
		value = JSON.parse(window.localStorage.getItem(PREFIX + key));
	}
	catch (e) {
		logError(e, `getLocalStorage - ${key}`);
	}
	return value;
}

export function setLocalStorage(key, value) {
	try {
		window.localStorage.setItem(PREFIX + key, JSON.stringify(value));
	}
	catch (e) {
		logError(e, `setLocalStorage - ${key}`);
	}
}

export function getSessionStorage(key) {
	let value;
	try {
		value = JSON.parse(window.sessionStorage.getItem(PREFIX + key));
	}
	catch (e) {
		logError(e, `getSessionStorage - ${key}`);
	}
	return value;
}

export function setSessionStorage(key, value) {
	try {
		window.sessionStorage.setItem(PREFIX + key, JSON.stringify(value));
	}
	catch (e) {
		logError(e, `setSessionStorage - ${key}`);
	}
}

export function getPaidUAFromLocalStorage() {
	let paidUA = getLocalStorage(PAID_UA);
	if (paidUA?.firstSeen + SUBS_OFFER_LENGTH < Date.now()) {
		localStorage.removeItem(PAID_UA);
		paidUA = undefined;
	}
	return paidUA;
}

export function isLive(feature) {
	let { [USER]: user } = store.getState();
	return user?.segments?.includes(feature);
}

export function getCouponCodeFromLocalStorage() {
	let couponCode = getLocalStorage(SUBS_OFFER_COUPON);
	if (couponCode?.firstSeen + SUBS_OFFER_LENGTH < Date.now()) {
		localStorage.removeItem(SUBS_OFFER_COUPON);
		couponCode = undefined;
	}
	return couponCode;
}

export function setUpsellSeenInLocalStorage(location) {
	setLocalStorage(UPSELL_SEEN, { location, lastSeen: Date.now() });
}

export function getUpsellSeenInLocalStorage() {
	let { lastSeen, location } = getLocalStorage(UPSELL_SEEN) || {};
	let now = Date.now();
	if (now - lastSeen > FIVE_MINUTES) {
		localStorage.removeItem(UPSELL_SEEN);
		location = undefined;
	}
	return location;
}

export function formatDate(timestamp) {
	return new Date(timestamp).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
}

/**
 * adds retry functionality
 * @param func a function that does the work, can return a promise
 * @param maxRetries max number of times to retry
 * @param retries used internally for recursion, do not pass
 */
export async function withRetries(func, maxRetries, retries = 0) {
	let result;
	try {
		result = func();
		if (result.then) { // result is promise like so await
			result = await result;
		}
	}
	catch (e) {
		if (retries < maxRetries) {
			await sleep(Math.pow(2, retries + 1) * 1000);
			return withRetries(func, maxRetries, retries + 1);
		}
		throw e;
	}
	return result;
}

/**
 * navigates user to previous route in browser history
 */
export function onNavigateBack(location = 'header-backButton') {
	let prevUrl = getLocalStorage('prevUrl');
	if (prevUrl) {
		dispatchEvent(CLICK, { [CLICK_LOCATION]: location,  [DESTINATION_URL]: prevUrl } );
		sendScrollEvent();
		window.history.back();
	}
	else {
		let redirectUrl = getInternalUrlFromParam('redirect');
		loadUrl(redirectUrl, location);
	}
}
